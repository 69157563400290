.ql-container {
    min-height: 30vh;
}

body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.dark-green-button {
  background-color: #033833; /* Dark green color */
  color: white; /* Optional: changes the text color to white for better contrast */
}

@media print {
    .MuiTable-root {
      width: 100%;
      max-width: 100%; 
    }
    .MuiPaper-root {
      overflow: visible !important;
    }
    .MuiTableCell-root {
      padding: 8px; 
      font-size: 12px; 
      word-wrap: break-word;
    }
    
    .MuiTableContainer-root {
      overflow-x: auto; 
    }
    .MuiBox-root, .no-break {
      page-break-inside: avoid;
    }
    .dialog-buttons, .MuiDialogActions-root {
        display: none !important;
    }
    
    body * {
      break-after: avoid;
      break-inside: avoid;
      break-before: avoid;
      visibility: hidden;
    }
    #print-content, #print-content * {
      visibility: visible;
    }
    #print-content {
      position: absolute;
      left: 0;
      top: 0;
      width:100%;
    }


}
  

.react-resizable-handle {

    position: absolute;
    height: 12px !important;
    width: 12px !important;
    background-color: white;
    border: 1px solid rgb(119, 119, 119);
    box-sizing: border-box;
    opacity: 0.8;
    cursor: nwse-resize;
    right: -6px;
    bottom: -6px;
    background-image: none !important;

}

.letterhead-remove-image {
    position: absolute;
    right: -30px;
    top: -10px;
}


#createAccountSubscriptionPlanName {
    display: flex;
    padding-right: 40px !important;
    padding-top: 24px !important;
    height: 27px;
}

.irs--modern .irs-grid-text {
    font-size: 9px !important;
}


@media screen and (max-height: 950px) {    
    .MuiDrawer-paper {
        height: calc(95% - 40px) !important
    }
}

@keyframes textanim {
    30% {
       text-shadow: 0 0 8px rgba(166, 166, 166, 0.818); 
    }
 }

.quill {
  .ql-container {
    min-height: 200px;
  }

  #emoji-palette {
    #tab-panel {
      padding-top: 12px;
      .bem {
        height: 27px;
      }
    }
  }
}